import React from 'react';
import kapil from '../assets/img/kapil.jpg'

function Courses() {
    return (
        <div>
            <div className='d-flex flex-wrap justify-content-center mt-5 bg-light p-3 mx-5 rounded-4'>
                <h4>Contact Us</h4>
            </div>
            <div className='my-4 d-flex flex-wrap text-start justify-content-center'>
                <div className=' mx-5 my-3'>
                    <h4>Trustee Details</h4>
                    <div>
                        <span>Trust Name :- Life Guidance Trust</span><br />
                        <span>President Name :- Mr. Shailendra Kumar</span><br />
                        <span>Manager :- Mr. Keshari Prasad</span><br />
                        <span>Treasurer :- Mrs. Reeta Devi</span><br />
                    </div>
                </div>
                <div className=' mx-5 my-3'>
                    <h4>Connect us</h4>
                    <div>
                        <span>Mobile No. :- +91 9670927047</span><br />
                        <span>Mobile No. :- +91 9839721293</span><br />
                        <span>Email. :- contact@lifeguidance.in</span><br />
                        <span>Email. :- help@lifeguidance.in</span><br />
                    </div>
                </div>
            </div>
            <div className='my-4 d-flex flex-wrap text-start justify-content-center'>
                <div className=' mx-5 my-3'>
                    <h4>Bank Details</h4>
                    <div>
                        <span>Account Holder Name :- Life Guidance Trust</span><br />
                        <span>Account Number :- 353901010037366</span><br />
                        <span>IFSC Code :- UBIN0535397</span><br />
                        <span>Branch Code :- Maharaj Ganj</span><br />
                    </div>
                </div>
                <div className=' mx-5 my-3'>
                    <h4>Trust Details</h4>
                    <div>
                        <span>Registration No. :- 202200982001432</span><br />
                        <span>Act Name :- 1882</span><br />
                        <span>PAN No :- AACT*****N</span><br />
                        <span>NITI Aayog Unique ID :- ********</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Courses;