import React from 'react';
import institute_open from '../assets/gallery/institute_open.jpg'
import trust_1 from '../assets/gallery/trust_1.jpg'
import trust_2 from '../assets/gallery/trust_2.jpg'
import trust_3 from '../assets/gallery/trust_3.jpg'
import trust_4 from '../assets/gallery/trust_4.jpg'
import trust_5 from '../assets/gallery/trust_5.jpg'
import trust_6 from '../assets/gallery/trust_6.jpg'
import trust_7 from '../assets/gallery/trust_7.jpg'
import trust_8 from '../assets/gallery/trust_8.jpg'
import trust_9 from '../assets/gallery/trust_9.jpg'
import trust_10 from '../assets/gallery/trust_10.jpg'
import trust_11 from '../assets/gallery/trust_11.jpg'
import trust_12 from '../assets/gallery/trust_12.jpg'
import trust_13 from '../assets/gallery/trust_13.jpg'
import trust_14 from '../assets/gallery/trust_14.jpg'
import trust_15 from '../assets/gallery/trust_15.jpg'

function Gallery() {
    return (
        <div>
            <div className='d-flex flex-wrap justify-content-center mt-5 bg-light p-3 mx-5 rounded-4'>
                <h4>Gallery</h4>
            </div>
            <div className='d-flex flex-wrap text-center justify-content-center'>
                <img className='m-2' src={institute_open} alt="" style={{ width: "30%", height: "", aspectRatio: 3/2 , objectFit: 'cover'}} />
                <img className='m-2' src={trust_1} alt="" style={{ width: "30%", height: "", aspectRatio: 3/2 , objectFit: 'cover' }} />
                <img className='m-2' src={trust_2} alt="" style={{ width: "30%", height: "", aspectRatio: 3/2 , objectFit: 'cover' }} />
                <img className='m-2' src={trust_3} alt="" style={{ width: "30%", height: "", aspectRatio: 3/2 , objectFit: 'cover' }} />
                <img className='m-2' src={trust_4} alt="" style={{ width: "30%", height: "", aspectRatio: 3/2 , objectFit: 'cover' }} />
                <img className='m-2' src={trust_5} alt="" style={{ width: "30%", height: "", aspectRatio: 3/2 , objectFit: 'cover' }} />
                <img className='m-2' src={trust_6} alt="" style={{ width: "30%", height: "", aspectRatio: 3/2 , objectFit: 'cover' }} />
                <img className='m-2' src={trust_7} alt="" style={{ width: "30%", height: "", aspectRatio: 3/2 , objectFit: 'cover' }} />
                <img className='m-2' src={trust_8} alt="" style={{ width: "30%", height: "", aspectRatio: 3/2 , objectFit: 'cover' }} />
                <img className='m-2' src={trust_9} alt="" style={{ width: "30%", height: "", aspectRatio: 3/2 , objectFit: 'cover' }} />
                <img className='m-2' src={trust_10} alt="" style={{ width: "30%", height: "", aspectRatio: 3/2 , objectFit: 'cover' }} />
                <img className='m-2' src={trust_11} alt="" style={{ width: "30%", height: "", aspectRatio: 3/2 , objectFit: 'cover' }} />
                <img className='m-2' src={trust_12} alt="" style={{ width: "30%", height: "", aspectRatio: 3/2 , objectFit: 'cover' }} />
                <img className='m-2' src={trust_13} alt="" style={{ width: "30%", height: "", aspectRatio: 3/2 , objectFit: 'cover' }} />
                <img className='m-2' src={trust_14} alt="" style={{ width: "30%", height: "", aspectRatio: 3/2 , objectFit: 'cover' }} />
                <img className='m-2' src={trust_15} alt="" style={{ width: "30%", height: "", aspectRatio: 3/2 , objectFit: 'cover' }} />

            </div>
        </div>
    );
}

export default Gallery;