import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import App from './App';
import Home from './core/home';
import About from './pages/about';
import Contact from './pages/contact';
import reportWebVitals from './reportWebVitals';
import Gallery from './pages/gallery';
import PrivacyPolicy from './pages/app_pri_pol';


const router = createBrowserRouter([
  {
    path: "/",
    publicPath: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <Home />
      },
      {
        path: "about",
        element: <About />
      },
      {
        path: "contact",
        element: <Contact />
      },
      {
        path: "gallery",
        element: <Gallery />
      },
      {
        path: "lgci-privacy",
        element: <PrivacyPolicy />
      }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
