import React from 'react';
import './style.css'
import { NavLink } from 'react-router-dom';
import 'bootstrap';
import logo from '../assets/img/logo.jpg'

function Header() {
    return (
        <nav className="navbar sticky-top border-bottom shadow" style={{ backgroundColor: "#fff" }}>
            <div className="container-fluid">
                <nav className="navbar">
                    <div className="container-fluid">
                        <img src={logo} alt="" width={40} style={{backgroundBlendMode: "initial"}} />
                        <NavLink to="/" className="fs-4 fw-semibold text-deco-none ms-2" style={{ color: "black" }}>Life Guidance Trust</NavLink>
                    </div>
                </nav>
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">
                        <span className="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-expanded="false" aria-label="Toggle navigation">
                            <span className='fa fa-bars' style={{ color: "#000" }} />
                        </span>

                        <div className="offcanvas offcanvas-end" style={{ paddingInline: "20px", backgroundColor: "#fff", color: "#000", width: "80%" }} id="offcanvasNavbar">
                            <div class="offcanvas-header">
                                <h5 class="offcanvas-title" id="offcanvasNavbarLabel">Offcanvas</h5>
                                <span type="button" data-bs-dismiss="offcanvas" aria-label="Close" className='fa fa-times' style={{ padding: "10px", color: "#000" }} />
                            </div>
                            <div className="navbar-nav" data-bs-dismiss="offcanvas">
                                <NavLink className="text-pad-start fw-medium text-deco-none text-col" activeClassName="acive" to="/">Home</NavLink>
                                <NavLink className="text-pad-start fw-medium text-deco-none text-col" activeClassName="acive" to="gallery">Gallery</NavLink>
                                <NavLink className="text-pad-start fw-medium text-deco-none text-col" activeClassName="acive" to="about">About us</NavLink>
                                <NavLink className="text-pad-start fw-medium text-deco-none text-col" activeClassName="acive" to="contact">Contact us</NavLink>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </nav>
    );
}

export default Header;