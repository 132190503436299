import React from 'react';
import trustImg_1 from '../assets/img/institute_img.jpg';
import trustImg_2 from '../assets/img/institute_img1.jpg';
import institute_open from '../assets/img/institute_open.jpg';
import kapil from '../assets/img/kapil.jpg';
import trust_f_1 from '../assets/img/trust_1.jpg';
import trust_f_2 from '../assets/img/trust_2.jpg';
import './style.css';
import { Container } from 'react-bootstrap';

function Home() {
    return (
        <div>
            <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active corousel-mb">
                        <img src={trustImg_1} className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item corousel-mb">
                        <img src={trustImg_2} className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item corousel-mb">
                        <img src={institute_open} className="d-block w-100" alt="..." />
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            <div className='d-flex flex-wrap justify-content-center my-4'>
                <div className='py-2 px-5 text-center border border-primary rounded-3 bg-primary' style={{ color: "#fff" }} >
                    <h4>Director</h4>
                    <h4>
                        Mr. Shailendra Kumar ( Kapil )
                    </h4>
                </div>
            </div>

            <div className='kapil-box flex-wrap'>
                <div className='m-2 text-center mt-2'>
                    <img src={kapil} alt="Director" className='kapil border border-dark rounded-2' />
                </div>
                <div className='border border-dark p-2 px-5 text-center rounded-2' style={{ flex: "1" }}>
                    <p>I would like to inform all of you that we have done social work with many NGOs in the field of disability, such as Arunima Foundation and Saubhagya Foundation [ Lucknow ], and ORHCW [ Delhi ]. After gaining experience in various institutes like these, I am providing free training for handicapped and underprivileged children. I am able to cover their expenses without relying on fees from other children.</p>
                    <p>मैं आप सभी को सूचित करना चाहता हूँ कि हमने कई एनजीओ में दिव्यांगों के क्षेत्र में सामाजिक कार्य किया है, जैसे कि अरुणिमा फाउंडेशन और सौभाग्य फाउंडेशन [ लखनऊ ], और ORHCW [ दिल्ली ]। इन तरह के विभिन्न संस्थाओं में अनुभव प्राप्त करने के बाद, मैं विकलांग और गरीब बच्चों के लिए मुफ्त प्रशिक्षण प्रदान कर रहा हूँ। मैं अन्य बच्चों के शुल्क से किसी भी सहारे के बिना उनका खर्चा संभाल सकता हूँ।</p>
                </div>
            </div>
            <div className='mt-3 p-3' style={{paddingInline: "10%"}}>
                <Container>
                <div className='d-flex flex-wrap fw-bolder justify-content-center'>
                    <div className='border border-dark p-2 rounded-2' style={{ flex: "1" }}>
                        <span style={{ fontSize: "15px" }}>Life Guidance Trust के अंतर्गत विकलांग व गरीब बच्चों के लिए शिक्षा के क्षेत्र में Computer Institute का उद्घाटन किया गया , जिसमे समस्त क्षेत्रवासी व बच्चों के अभिभावक उपस्थित हुए | </span>
                        <br />
                        <br />
                        <h5 className='fw-300'>Motive :- </h5>
                        <span style={{ fontSize: "15px" }}>"कंप्यूटर संस्थान खोलने के पीछे की मुख्य उद्देश्य, विशेष रूप से विकलांग बच्चों के लिए, यह मानवाधिकार की श्रद्धा में निहित है कि हर किसी को शिक्षा और कौशल विकास के लिए समान अवसर मिलना चाहिए। एक सहायक और समावेशी शिक्षा वातावरण प्रदान करके, हम विकलांग बच्चों को आवश्यक कंप्यूटर कौशल प्राप्त करने में सशक्त करने का उद्देश्य रखते हैं ताकि उनकी स्वतंत्रता में सुधार हो सके और उनके भविष्य की रोजगार की संभावनाओं को बेहतर बनाया जा सके।"</span>
                    </div>
                    <div className='ms-2 text-center pt-2' style={{ overflow: "hidden"}}>
                        <img src={trust_f_1} alt="Director" className='border border-dark rounded-2' style={{ width: "500px", height: "100%", objectFit: "contain" }} />
                    </div>
                </div>
                </Container>
            </div>
            <div className='mt-3 p-3'>
                <Container>
                    <div className='d-flex flex-wrap fw-bolder justify-content-center'>
                        <div className='ms-1 text-center' style={{ overflow: "hidden"}}>
                            <img src={trust_f_2} alt="Director" className='border border-dark rounded-2' style={{ width: "500px", height: "100%", objectFit: "contain" }} />
                        </div>
                        <div className='border border-dark p-2 rounded-2 mt-2 ms-2' style={{ flex: "1" }}>
                            <span style={{ fontSize: "15px" }}>Life Guidance Trust के अंतर्गत असहाय महिलाओं को मातृ दिवस के उपलक्ष में उपहार दिया गया , जिसमे समस्त क्षेत्रवासी व बच्चों के अभिभावक उपस्थित हुए | </span>
                            <br />
                            <br />
                            <h5 className='fw-300'>Motive :- </h5>
                            <span style={{ fontSize: "15px" }}>मातृ दिवस पर, हमने असहाय महिलाओं को उपहार देने का सौभाग्य प्राप्त किया। उन्हें सम्मान के चिन्ह मिलते हुए देखकर हमें बहुत अच्छा लगा। उनके चेहरों पर खुशी और आभार के भाव देखना अनुभव की अपार सुखद था। ये महिलाएं, विभिन्न चुनौतियों और कठिनाइयों का सामना कर रही हैं, हर दिन प्रतिष्ठा और समर्थन के पात्र हैं, लेकिन इस विशेष अवसर पर, उन्हें प्यार और सम्मान का एहसास कराना विशेष रूप से महत्वपूर्ण था। हमारी आशा है कि ये छोटे-मोटे उपहार उनके चेहरों पर मुस्कान लाई होगी और उन्हें यह याद दिलाया जाएगा कि वे हमारी समुदाय के महत्वपूर्ण और प्रिय सदस्य हैं।</span>
                        </div>
                    </div>
                </Container>
            </div>
            <div className='mt-3 p-3'>
                <Container>
                    <div className='d-flex flex-wrap fw-bolder justify-content-center'>
                        <div className='border border-dark p-2 rounded-2' style={{ flex: "1" }}>
                            <span style={{ fontSize: "15px" }}>Life Guidance Trust के अंतर्गत असहाय महिलाओं को मातृ दिवस के उपलक्ष में उपहार दिया गया , जिसमे समस्त क्षेत्रवासी व बच्चों के अभिभावक उपस्थित हुए | </span>
                            <br />
                            <br />
                            <h5 className='fw-300'>Motive :- </h5>
                            <span style={{ fontSize: "15px" }}>मातृ दिवस पर, हमने असहाय महिलाओं को उपहार देने का सौभाग्य प्राप्त किया। उन्हें सम्मान के चिन्ह मिलते हुए देखकर हमें बहुत अच्छा लगा। उनके चेहरों पर खुशी और आभार के भाव देखना अनुभव की अपार सुखद था। ये महिलाएं, विभिन्न चुनौतियों और कठिनाइयों का सामना कर रही हैं, हर दिन प्रतिष्ठा और समर्थन के पात्र हैं, लेकिन इस विशेष अवसर पर, उन्हें प्यार और सम्मान का एहसास कराना विशेष रूप से महत्वपूर्ण था। हमारी आशा है कि ये छोटे-मोटे उपहार उनके चेहरों पर मुस्कान लाई होगी और उन्हें यह याद दिलाया जाएगा कि वे हमारी समुदाय के महत्वपूर्ण और प्रिय सदस्य हैं।</span>
                        </div>
                        <div className='ms-2 text-center mt-2' style={{ overflow: "hidden"}}>
                            <img src={trust_f_2} alt="Director" className='border border-dark rounded-2' style={{ width: "500px", height: "100%", objectFit: "contain" }} />
                        </div>
                    </div>
                </Container>
            </div>img/
        </div>
    );
}

export default Home;