import React from 'react';
import kapil from '../assets/img/kapil.jpg';
import virendra from '../assets/img/virendra_trust.jpg';
import sarvesh from '../assets/img/sarvesh_trust.jpg';
import reeta from '../assets/img/reeta_trust.jpg';
import keshri from '../assets/img/keshri_trust.jpg';
import anand from '../assets/img/anand_trust.jpg';
import manoj from '../assets/img/manoj_trust.jpg';
import dharmendra from '../assets/img/Dharmendra_trust.jpg';
import anjit_trust from '../assets/img/anjit_trust.jpg';

function About() {
    return (
    <div>
        <div className='d-flex flex-wrap justify-content-center mt-5 bg-light p-3 mx-5 rounded-4'>
            <h4>About Us</h4>
        </div>
        <div className='my-2 d-flex flex-wrap justify-content-center' style={{ paddingInline: "5%" }}>
            <div className='m-3 text-center'>
                <img className='rounded-4' src={kapil} alt="" width={220} height={250} />
                <div className='my-2' style={{ fontSize: "14px" }}>
                    <span>President :- Mr. Shailendra Ji</span>
                </div>
            </div>
        </div>
        <div className='my-2 d-flex flex-wrap justify-content-center' style={{ paddingInline: "5%" }}>
            <div className='m-3 text-center'>
                <img className='rounded-4' src={keshri} alt="" width={220} height={250} />
                <div className='my-2' style={{ fontSize: "14px" }}>
                    <span>Manager :- Mr. Keshari Prasad</span>
                </div>
            </div>
            <div className='m-3 text-center'>
                <img className='rounded-4' src={reeta} alt="" width={220} height={250} />
                <div className='my-2' style={{ fontSize: "14px" }}>
                    <span>Treasurer :- Mrs. Reeta Devi</span>
                </div>
            </div>
            <div className='m-3 text-center'>
                <img className='rounded-4' src={sarvesh} alt="" width={220} height={250} />
                <div className='my-2' style={{ fontSize: "14px" }}>
                    <span>Member :- Mr. Sarvesh Kumar</span>
                </div>
            </div>
            <div className='m-3 text-center'>
                <img className='rounded-4' src={manoj} alt="" width={220} height={250} />
                <div className='my-2' style={{ fontSize: "14px" }}>
                    <span>Member :- Mr. Manoj Kumar</span>
                </div>
            </div>
        </div>
        <div className='my-2 d-flex flex-wrap justify-content-center' style={{ paddingInline: "5%" }}>
            <div className='m-3 text-center'>
                <img className='rounded-4' src={anand} alt="" width={220} height={250} />
                <div className='my-2' style={{ fontSize: "14px" }}>
                    <span>Member :- Mr. Anand Kumar</span>
                </div>
            </div>
            <div className='m-3 text-center'>
                <img className='rounded-4' src={dharmendra} alt="" width={220} height={250} />
                <div className='my-2' style={{ fontSize: "14px" }}>
                    <span>member :- Mr. Dharmendra Kumar</span>
                </div>
            </div>
            <div className='m-3 text-center'>
                <img className='rounded-4' src={virendra} alt="" width={220} height={250} />
                <div className='my-2' style={{ fontSize: "14px" }}>
                    <span>Member :- Mr. Virendra Kumar</span>
                </div>
            </div>
            <div className='m-3 text-center'>
                <img className='rounded-4' src={anjit_trust} alt="" width={220} height={250} />
                <div className='my-2' style={{ fontSize: "14px" }}>
                    <span>Member :- Mr. Anjit Kumar</span>
                </div>
            </div>
        </div>

    </div>);
}

export default About;