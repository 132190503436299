import React from 'react';
import logo from '../assets/img/logo.jpg';
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

function Footer() {
    return (
        <div className='mt-4 shadow-lg'>
            <Container fluid className='px-5 py-3 border border-dark border-bottom-0 border-end-0 border-start-0'>
                <Row>
                    <Col>
                        <div>
                            <h4>Donate us</h4>
                        </div>
                        <div className=''>
                            <span>Account Name: Life Guidance Trust</span>
                        </div>
                        <div className=''>
                            <span>Account No.: 353901010037366</span>
                        </div>
                        <div className=''>
                            <span>IFSC No.: UBIN0535397</span>
                        </div>
                        <div className=''>
                            <span>Branch Name.: Maharaj Ganj</span>
                        </div>
                    </Col>
                    <Col className='border border-dark border-bottom-0 border-top-0'>
                        <h3>Quick Links</h3>
                        <div className="navbar-nav">
                            <NavLink className="text-pad-start fw-medium text-deco-none text-col" activeClassName="acive" to="/">Home</NavLink>
                            <NavLink className="text-pad-start fw-medium text-deco-none text-col" activeClassName="acive" to="gallery">Gallery</NavLink>
                            <NavLink className="text-pad-start fw-medium text-deco-none text-col" activeClassName="acive" to="about">About us</NavLink>
                            <NavLink className="text-pad-start fw-medium text-deco-none text-col" activeClassName="acive" to="contact">Contact us</NavLink>
                        </div>
                    </Col>
                    <Col>
                        <h3>Contact us</h3>
                        <span>Address :- Maharaj Ganj , Prayagraj Road Badlapur Jaunpur</span>
                        <div>
                            <span>Contact No. 1 :- +91 9670927047 ( Director )</span>
                        </div>
                        <div>
                            <span>Contact No. 2 :- +91 8795524175 ( BaniDeveloepr )</span>
                        </div>
                        <div>
                            <span>Email :- trust@lifeguidance.in</span>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className='text-center p-2 border border-dark border-bottom-0 border-end-0 border-start-0'>
                <span>Copyright 2024 by Life Guidance Trust | All Right Reserved | Designed by BaniDeveloper.</span>
            </div>
        </div>
    );
}

export default Footer;