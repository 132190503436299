import React from 'react';

function PrivacyPolicy() {
    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
            <h1>Privacy Policy for LGCI - Computer Institute</h1>
            <p><strong>Effective Date:</strong> May 20, 2024</p>

            <h2>1. Introduction</h2>
            <p>
                Welcome to LGCI - Computer Institute, an educational application developed by Bani Developer.
                Your privacy is of utmost importance to us, and we are committed to protecting it. This Privacy Policy outlines how we collect,
                use, and safeguard your information when you use our app, particularly for the purpose of connecting people.
            </p>

            <h2>2. Information We Collect</h2>
            <h3>a. Personal Information</h3>
            <p>We may collect personal information that you provide to us directly, such as:</p>
            <ul>
                <li>Name</li>
                <li>Email address</li>
                <li>Phone number</li>
                <li>Profile picture</li>
                <li>Any other information you choose to provide</li>
            </ul>

            <h3>b. Non-Personal Information</h3>
            <p>We may also collect non-personal information about your use of the app, including but not limited to:</p>
            <ul>
                <li>Device information (device type, operating system, etc.)</li>
                <li>Log information (IP address, app usage data, access times, etc.)</li>
                <li>Location information (if you enable location services)</li>
            </ul>

            <h2>3. How We Use Your Information</h2>
            <p>We use the information we collect to:</p>
            <ul>
                <li>Provide, operate, and maintain our services</li>
                <li>Connect you with other users for educational and networking purposes</li>
                <li>Improve, personalize, and expand our services</li>
                <li>Communicate with you, including sending you updates, notifications, and support</li>
                <li>Monitor and analyze usage and trends to enhance user experience</li>
                <li>Comply with legal obligations</li>
            </ul>

            <h2>4. Information Sharing and Disclosure</h2>
            <p>We do not share or sell your personal information with third parties except in the following circumstances:</p>
            <ul>
                <li>With your consent</li>
                <li>With service providers who perform services on our behalf</li>
                <li>To connect you with other users within the app for educational purposes</li>
                <li>To comply with legal requirements, such as a law, regulation, or legal request</li>
                <li>To protect the rights, property, and safety of our users and the public</li>
            </ul>

            <h2>5. Data Security</h2>
            <p>
                We implement appropriate security measures to protect your information from unauthorized access, alteration, disclosure, or destruction.
                However, no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee its absolute security.
            </p>

            <h2>6. Your Choices</h2>
            <p>You have the following rights regarding your personal information:</p>
            <ul>
                <li>Access: You can request access to the personal information we hold about you.</li>
                <li>Correction: You can request that we correct any inaccuracies in your personal information.</li>
                <li>Deletion: You can request that we delete your personal information.</li>
                <li>Opt-out: You can opt out of receiving promotional communications from us.</li>
            </ul>
            <p>To exercise any of these rights, please contact us at the contact information provided below.</p>

            <h2>7. Children’s Privacy</h2>
            <p>
                Our app is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13.
                If we become aware that we have inadvertently received personal information from a child under 13, we will delete such information from our records.
            </p>

            <h2>8. Changes to This Privacy Policy</h2>
            <p>
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the effective date.
                You are advised to review this Privacy Policy periodically for any changes.
            </p>

            <h2>9. Contact Us</h2>
            <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
            <p>
                Bani Developer<br />
                Email: <a href="mailto:support@lgci.com">support@lgci.com</a><br />
                Phone: +123-456-7890
            </p>

            <p>By using LGCI - Computer Institute, you agree to the terms of this Privacy Policy. Thank you for trusting us with your information.</p>
        </div>
    );
};

export default PrivacyPolicy;